<template>
	<div class=''>
		<mzSwiper :image='bannerImg'></mzSwiper>

		<!-- 导航栏 -->
		<div class="flex flex-wrap row-between ">
			<div :class="['nav_item mb5',item.id==id?'nav_item_avtive':'',i==5?'w-max':'']" v-for="(item,i) in tabList"
				:key="item.id" @click="checkTab(item.id)">{{item.categoryName}}
			</div>
		</div>
		<!-- //导航栏 -->

		<!-- 内容区域 -->
		<div class=" pl16 pr16 pb30">
			<!-- <div class="pl34 pr34 fs11 color0 text-center">文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案</div> -->
			<div class="mt20 pt20 pb18  flex row-column col-center" style="background: #F9F9F9;"
				v-for="(item,i) in reportList" :key="i">
				<div class="fs16 fw-500 color0">{{item.patentName}}</div>
				<div class="fs12 color6 mt10 mb21">{{item.patentId}}</div>
				<img :src="item.wapPic" class="w193 h246">
				<van-button type="default" class="mt18" @click="godoD(item.id)">查看证书></van-button>
			</div>
		</div>
		<!-- //内容区域 -->
	</div>
</template>

<script>
	//引入顶部banner的minix
	import bannerImgJs from '@/mixin/bannerImg.js'
	import { getPatentsCategory, getPatents } from '@/api'
	export default {
		data() {
			return {
				id: null,
				bannerType: 5,
				tabList: [],
				reportList: []
			};
		},
		mixins: [bannerImgJs],
		components: {},

		created() {
			this.id = this.$route.params.id
			this.getPatentsCategory()
		},
		watch: {
			'$route': function(to, from) {
				this.id = this.$route.params.id
				this.getPatentsCategory()
			}
		},
		mounted() {},

		methods: {
			//获取分类
			async getPatentsCategory() {
				const res = await getPatentsCategory()
				this.tabList = res.data
				this.id ? '' : this.id = res.data[0].id
				this.getPatents()
			},
			//获取专利报告
			async getPatents() {
				const res = await getPatents({ id: this.id })
				this.reportList = res.data
			},
			//切换tab栏
			checkTab(id) {
				this.id = id
				this.getPatents()
			},
			godoD(id) {
				this.$router.push({
					name: "reportD",
					params: {
						id,
						CategoryId: this.id,
					},
				})
			}
		}
	}
</script>
<style lang='scss' scoped>
	.nav_item,
	.nav_item_avtive {
		width: 49.3%;
		height: 44px;
		background: #F2F2F2;
		font-size: 12px;
		font-weight: 500;
		color: #787878;
		line-height: 44px;
		text-align: center;
		font-weight: 500;
	}

	.nav_item_avtive {
		color: #000000 !important;
		background: #F7B500 !important;
	}

	::v-deep .van-button {
		width: 127px;
		height: 28px;
		border: 1px solid #272727;
		font-size: 12px;
		font-weight: 500;
		color: #272727;
		line-height: 28px;
		text-align: center;
	}
</style>
